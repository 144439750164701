import {
  Button,
  ButtonProps,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuProps,
} from '@chakra-ui/react';
import { ComponentType, ReactNode } from 'react';
import { MdArrowDropDown } from 'react-icons/md';

type ItemType = {
  id: number;
  name: string;
  Icon?: ComponentType;
};

type PropsType = {
  menuItems: ItemType[];
  activeMenuItemId?: number;
  onMenuItemClick: (id: number) => void;
  showArrowIcon?: boolean;
  children: ReactNode;
} & ButtonProps &
  MenuProps;

const DropDownMenuWrapper = (props: PropsType) => {
  const {
    menuItems = [],
    activeMenuItemId,
    showArrowIcon = false,
    onMenuItemClick,
    children,
    gutter,
    ...rest
  } = props;

  return menuItems.length > 1 ? (
    <Menu autoSelect={false} gutter={gutter} isLazy>
      <MenuButton
        as={Button}
        h='fit-content'
        my={0}
        p={0}
        borderRadius={0}
        bgColor='transparent'
        _hover={{ bgColor: 'transparent' }}
        _active={{ bgColor: 'transparent' }}
        {...(showArrowIcon ? { rightIcon: <MdArrowDropDown />, pr: 3 } : {})}
        {...rest}
      >
        {children}
      </MenuButton>
      <MenuList boxShadow='md' zIndex={101}>
        {menuItems.map(({ id, name, Icon: ItemIcon }) => (
          <MenuItem
            key={id}
            onClick={() => onMenuItemClick(id)}
            {...(activeMenuItemId === id
              ? { bgColor: 'primary.100' }
              : { _hover: { bgColor: 'gray.50' } })}
          >
            {Icon && <Icon as={ItemIcon} mr={2} />}
            {name}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  ) : (
    children
  );
};

export default DropDownMenuWrapper;
